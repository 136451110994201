import React from "react";
import { SocialIcon } from 'react-social-icons';

const TopBar = () => {
    return (
        <div className="bg-gray-800 text-white text-sm py-2">
            <div className="container mx-auto px-4">
                {/* Laptop View: Single Line */}
                <div className="hidden md:flex justify-between items-center">
                    <span>One stop to all your industrial solutions – Contact Us Today!</span>
                    <div className="flex space-x-6">
                        <span className="flex items-center">
                            <i className="bi bi-telephone-fill mr-2"></i> +1 (240) 885-1367
                        </span>
                        <span className="flex items-center">
                            <i className="bi bi-telephone-fill mr-2"></i> (+966) 557 629 896
                        </span>
                        <span className="flex items-center">
                            <i className="bi bi-envelope-fill mr-2"></i> info@aotcllcus.com
                        </span>
                        <div className="flex space-x-4 items-center">
                            <a
                                href="https://x.com/AOTCLLC"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-lg cursor-pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-twitter-x"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                </svg>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/105356242/admin/dashboard/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-lg cursor-pointer"
                            ><i className="bi bi-linkedin text-lg cursor-pointer"></i>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Mobile View: Three Lines */}
                <div className="flex flex-col md:hidden space-y-1">
                    <p className="text-center font-semibold">
                        One stop to all your industrial solutions – Contact Us Today!
                    </p>
                    <div className="flex justify-center space-x-4">
                        <span className="flex items-center">
                            <i className="bi bi-telephone-fill mr-2"></i> +1 (240) 885-1367
                        </span>
                        <span className="flex items-center">
                            <i className="bi bi-telephone-fill mr-2"></i> (+966) 557 629 896
                        </span>
                    </div>
                    <div className="flex justify-center items-center space-x-4">
                        <span className="flex items-center">
                            <i className="bi bi-envelope-fill mr-2"></i> info@aotcllcus.com
                        </span>
                        <div className="flex space-x-4 items-center">
                            <a
                                href="https://x.com/AOTCLLC"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-lg cursor-pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-twitter-x"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                </svg>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/105356242/admin/dashboard/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-lg cursor-pointer"
                            ><i className="bi bi-linkedin text-lg cursor-pointer"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TopBar;
