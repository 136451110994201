import React from 'react';
import img1 from '../assets/images/3m.png';
import img2 from '../assets/images/bosch.png';
import img3 from '../assets/images/dewalt.png';
import img4 from '../assets/images/duracell.png';
import img5 from '../assets/images/eisco.png';
import img6 from '../assets/images/makita.png';
import img7 from '../assets/images/mcmaster.png';
import img8 from '../assets/images/stanley.png';
import img9 from '../assets/images/werner.png';
import img10 from '../assets/images/x80proto.png';
import img11 from '../assets/images/ridgid.png';
import img12 from '../assets/images/sullair.png';



const clients = [
    { src: img1, alt: '3M' },
    { src: img2, alt: 'Bosch' },
    { src: img3, alt: 'Dewalt' },
    { src: img4, alt: 'Duracell' },
    { src: img5, alt: 'EISCO' },
    { src: img6, alt: 'Makita' },
    { src: img7, alt: 'Mcmaster' },
    { src: img8, alt: 'Stanley' },
    { src: img9, alt: 'Werner' },
    { src: img10, alt: 'Proto' },
    { src: img11, alt: 'RIDGID' },
    { src: img12, alt: 'SULLAIR' },

];

const TradingPage = () => {
    return (
        <div className="py-16 bg-gray-100">
            <div className="container mx-auto px-4">
                <h1 className="text-4xl font-bold text-blue-900 mb-8">Trading Services</h1>
                <p className="list-disc pl-6 text-lg text-gray-700 mb-8 font-semibold">
                    At American Overseas Trading & Contracting LLC (AOTC), we offer an extensive range of comprehensive trading solutions tailored to meet the diverse needs of our clients across the globe. Our services include global import and export, logistics, and in-depth market analysis to provide high-quality products and reliable service to each client, regardless of their location.

                    Our expertise in logistics and supply chain management ensures that clients receive personalized, end-to-end service, from product sourcing to delivery. We understand the complexities of the industrial equipment market and leverage our strong network to procure premium products from trusted manufacturers, locally and internationally. This allows us to offer competitive pricing, efficient turnaround times, and access to a vast market of industrial tools and equipment.

                    Our clients benefit from a seamless experience in placing custom orders. Whether you need specialized tools or bulk industrial supplies, we manage the process of sourcing from various reputable manufacturers and handle the shipping logistics to deliver products straight to your doorstep. Our robust network of suppliers enables us to provide the best prices while ensuring that each product meets stringent standards for quality and performance.

                    With our specialized services in market analysis and procurement, we help clients navigate the competitive landscape of industrial tools and equipment. Additionally, we provide customs compliance and documentation assistance to simplify the import/export process and ensure regulatory compliance across different regions.                </p>

                < h1 className="text-4xl font-bold text-blue-900 mb-8" >Our Products</h1>
                <section className="py-16 bg-gray-100 text-center">
                    <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 px-4">
                        {clients.map((client, index) => (
                            <div
                                key={index}
                                className="flex justify-center items-center transition-transform duration-300 hover:scale-105"
                            >
                                <img
                                    src={client.src}
                                    alt={client.alt}
                                    className="w-40 h-auto object-contain"
                                />
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div >
    );
};

export default TradingPage;
