import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import slide1 from '../assets/images/hero-bg.jpg';
import slide2 from '../assets/images/welding.jpg';
import slide3 from '../assets/images/refinery.jpg';


const HeroSection = () => {
    const slides = [
        {
            image: slide1,
            title: 'American Overseas Trading and Contracting',
            subtitle: 'Your Trusted Partner in Global Trading & Contracting',
            link: '/about',
            buttonText: 'Discover More',
        },
        {
            image: slide2,
            title: 'Innovative Contracting Solutions',
            subtitle: 'We deliver excellence in every trade.',
            link: '/contracting',
            buttonText: 'View Contracting Services',
        },
        {
            image: slide3,
            title: 'Global Solutions for Modern Challenges',
            subtitle: 'Empowering industries worldwide.',
            link: '/trading',
            buttonText: 'View Trading Services',
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 4000); // Change slide every 5 seconds
        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <section
            className="relative h-[80vh] bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${slides[currentSlide].image})` }}
        >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="relative z-10 text-center text-white px-4">
                <h1 className="text-4xl md:text-6xl font-arial">{slides[currentSlide].title}</h1>
                <p className="mt-2 text-lg">{slides[currentSlide].subtitle}</p>
                <Link to={slides[currentSlide].link}>
                    <button className="mt-6 px-8 py-3 bg-red-600 text-white font-semibold rounded-full hover:bg-red-700">
                        {slides[currentSlide].buttonText}
                    </button>
                </Link>

            </div>
            <div className="absolute bottom-5 flex justify-center space-x-2">
                {slides.map((_, index) => (
                    <span
                        key={index}
                        onClick={() => setCurrentSlide(index)}
                        className={`h-3 w-3 rounded-full cursor-pointer ${index === currentSlide ? 'bg-white' : 'bg-gray-500'
                            }`}
                    ></span>
                ))}
            </div>
        </section>
    );
};

export default HeroSection;
