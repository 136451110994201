import React, { useState } from 'react';
import Swal from 'sweetalert2';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        requestType: 'Request for Quotation',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        const { name, email, contact, requestType, description } = formData;

        if (!name) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please enter your name.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        if (!email) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please enter your email address.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        if (!contact) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please enter your contact number.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        if (!requestType) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please select a request type.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        if (!description) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please describe your request.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        const formUrl = 'https://formspree.io/f/meoqgnrl';

        try {
            const response = await fetch(formUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Submitted!',
                    text: 'Your request has been successfully submitted.',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#1d4ed8',
                });
                setFormData({
                    name: '',
                    email: '',
                    contact: '',
                    requestType: 'Request for Quotation',
                    description: '',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Submission Failed',
                    text: 'There was an issue submitting your request. Please try again later.',
                    confirmButtonColor: '#1d4ed8',
                });
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Submission Error',
                text: 'An error occurred while submitting the form. Please try again later.',
                confirmButtonColor: '#1d4ed8',
            });
        }
    };


    return (
        <div className="py-16 bg-gray-100 text-center">
            <h2 className="text-3xl font-bold text-blue-900 mb-8">Contact Us</h2>

            <div className="flex flex-col md:flex-row justify-between items-start space-y-10 md:space-y-0 md:space-x-10">
                {/* Left: Contact Form */}

                <div className="w-full md:w-1/2 bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-3xl font-bold text-blue-900 mb-8">Inquire Now</h2>

                    <p className="max-w-md mx-auto text-lg text-gray-700 mb-8">
                        Please fill out the form below to submit your request. Select "Request for Quotation" or "Request for Information" to get started.
                    </p>
                    <form onSubmit={handleSubmit} className="max-w-lg mx-auto space-y-4">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full p-3 border rounded"
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-3 border rounded"
                            required
                        />
                        <input
                            type="text"
                            name="contact"
                            placeholder="Your Contact Number"
                            value={formData.contact}
                            onChange={handleChange}
                            className="w-full p-3 border rounded"
                            required
                        />
                        <select
                            name="requestType"
                            value={formData.requestType}
                            onChange={handleChange}
                            className="w-full p-3 border rounded"
                            required
                        >
                            <option value="Request for Quotation">Request for Quotation (RFQ)</option>
                            <option value="Request for Information">Request for Information (RFI)</option>
                            <option value="Request for Proposal">Request for Proposal (RFP)</option>

                        </select>
                        <textarea
                            name="description"
                            placeholder="Describe your request"
                            value={formData.description}
                            onChange={handleChange}
                            className="w-full p-3 border rounded"
                            rows="5"
                            required
                        />
                        <button type="submit" className="px-6 py-3 bg-blue-900 text-white font-semibold rounded-lg hover:bg-blue-700">
                            Submit
                        </button>
                    </form>
                </div>
                <div className="w-full md:w-1/2 mt-10 md:mt-0 md:ml-10">
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-3xl font-bold text-blue-900 mb-8">Head Office</h2>
                        <p className="mb-2">
                            American Overseas Trading and Contracting LLC
                        </p>
                        <p className="mb-2">30 N Gould St</p>
                        <p className="mb-2">Sheridan, WY, 82801, USA</p>
                        <p className="mb-2">Email: info@aotcllcus.com</p>
                        <p className="mb-2">Phone: +1 (240) 885-1367</p>

                        {/* Google Map Embed */}
                        <div className="rounded-lg overflow-hidden">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.1674188312295!2d-106.9549483!3d44.797776600000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5335fabc2a66677f%3A0x8f85bd068d1afb8a!2s30%20N%20Gould%20St%2C%20Sheridan%2C%20WY%2082801%2C%20USA!5e0!3m2!1sen!2sca!4v1735991209352!5m2!1sen!2sca" width="600" height="450" style={{ border: 0 }}
                                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
