import React from 'react';
import CTASection from '../components/CTASection';
import drawing3D from '../assets/images/modelling.jpeg';
import drawingArchitectural from '../assets/images/architectural.jpeg';
import drawingFabrication from '../assets/images/fab shop drawing.jpeg';
import Presentation from '../assets/images/presentation.jpeg';
import VR from '../assets/images/VR.jpeg';



const services = [
    {
        title: 'Architectural Drawings',
        description: 'At AOTC, we provide meticulously detailed architectural plans and designs for a wide range of building projects. Our process emphasizes precision and involves multiple iterations to ensure the most accurate and suitable design tailored to the established application. We work closely with specialists across various disciplines to ensure seamless coordination and adherence to the highest industrial standards. By integrating expert input at every stage, our architectural drawings are not only technically sound but also optimized to meet functional, aesthetic, and regulatory requirements. This commitment to excellence ensures that our designs align with the unique needs and expectations of our clients.',
        img: drawingArchitectural,
    },
    {
        title: 'Fabrication Shop Drawings',
        description: 'At AOTC, we specialize in creating highly accurate fabrication shop drawings essential for manufacturing and assembly. Our drawings are meticulously crafted to reflect precise dimensions, material specifications, and connection details, ensuring seamless integration into the fabrication process. We prioritize coordination with project teams and specialists to uphold the highest industrial standards, delivering drawings that facilitate efficient, error-free production and on-site installation.',
        img: drawingFabrication,
    },
    {
        title: '3D Modeling and Rendering',
        description: 'AOTC offers advanced 3D modeling and rendering services that bring projects to life with unparalleled clarity and detail. Utilizing cutting-edge tools and software, we create realistic models and visuals that provide a comprehensive understanding of the project’s design and functionality. From conceptual visualization to detailed renderings, our team ensures every model is precise, accurate, and tailored to communicate the intended design effectively.',
        img: drawing3D,
    },
    {
        title: 'Presentation Preparation and Visualization Optimization',
        description: 'We provide end-to-end support in preparing impactful presentations and optimizing visualizations for client pitches, stakeholder engagement, and project approvals. Our team combines technical expertise with artistic flair to create compelling graphics, animations, and visual narratives that resonate with diverse audiences. With a focus on clarity and professionalism, our presentations elevate the communication of complex design concepts and project details.',
        img: Presentation,
    },
    {
        title: 'Virtual Reality (VR) Applications',
        description: 'AOTC leverages the transformative power of Virtual Reality (VR) to create immersive project experiences. Our VR applications enable clients to explore designs in a realistic, interactive environment, enhancing spatial understanding and decision-making. By integrating VR technology into our workflow, we provide an innovative platform for reviewing, refining, and finalizing designs with unmatched precision and confidence.',
        img: VR,
    },
];

const ContractingPage = () => {
    return (
        <div className="py-16 bg-gray-100">
            <div className="relative">

                <div className="container mx-auto px-4 relative">
                    <h1 className="text-4xl font-bold text-blue-900 mb-8">Contracting Services</h1>
                    <p className="text-lg text-gray-700 mb-6">


                    </p>

                    <ul className="list-disc pl-6 text-lg text-gray-700 mb-8">
                        <span className="font-semibold block mb-4">At American Overseas Trading & Contracting (AOTC), we pride ourselves on going beyond premium materials and equipment by offering a comprehensive suite of technical services to support our clients’ projects from inception to completion. Our expertise spans 3D designs in Revit and AutoCAD, architectural drawings, and fabrication drawings along with providing fabricated parts, stands, specialized tools, and various other solutions in our multiple fabrication shops all meticulously tailored to meet the unique requirements of each project.</span>

                        <span className="font-semibold block mb-4">Our team of skilled engineers and designers, alongside conceptual investigators, collaborates closely with clients to explore and identify the most innovative solutions for their projects. By leveraging cutting-edge tools and technologies, including the transformative power of artificial intelligence, we provide advanced analytical capabilities and design optimization, enabling smarter decision making and superior project outcomes.</span>

                        <span className="font-semibold block mb-4">With extensive experience in producing precise, high quality drawings, we ensure seamless integration across all project phases from initial design and engineering to construction and commissioning. Our work adheres strictly to project specifications and industry standards, ensuring efficient project planning and flawless execution.</span>

                        <span className="font-semibold block mb-4">By integrating AI-driven insights with creative problem solving, AOTC enhances its role as a forward thinking solutions provider, delivering unmatched value to clients across the oil and gas, power, utility, and chemical sectors. Our commitment to precision, reliability, and innovation empowers our clients to achieve operational success and project excellence, while positioning us at the forefront of industry advancements.</span>


                    </ul>

                    <div className="py-16">
                        <h1 className="text-3xl font-bold text-blue-900 mb-8">Our Specialized Services</h1>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {services.map((service, index) => (
                                <div
                                    key={index}
                                    className="p-6 bg-white rounded-lg shadow-lg"
                                >
                                    <img
                                        src={service.img}
                                        alt={service.title}
                                        className="w-full h-70 object-cover rounded-lg mb-4"
                                    />
                                    <h3 className="text-xl font-semibold text-red-600 mb-2">{service.title}</h3>
                                    <p className="text-gray-700">{service.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <CTASection
                        title="Let's Build Your Project Together"
                        description="Reach out to us today to discuss your contracting needs and how we can assist you in delivering successful projects."
                        buttonText="Contact Us"
                        buttonLink="/contact"
                    />
                </div>
            </div>
        </div>
    );
};

export default ContractingPage;
