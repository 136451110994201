import React from 'react';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import CTASection from '../components/CTASection';
import OurClients from '../components/OurClients';

const HomePage = () => {
    return (
        <div>
            <HeroSection />
            <Services />
            <CTASection
                title="Why Choose Us?"
                description="With decades of experience in the trading and contracting industry, we bring reliability, quality, and unmatched expertise to every project. Our dedication to client satisfaction sets us apart in the industry."
                buttonText="Learn More"
                buttonLink="/about"
            />
            <OurClients />
            <CTASection
                title="Ready to Work With Us?"
                description="Contact us today to discuss your project and see how we can help your business grow with our world-class trading and contracting solutions."
                buttonText="Contact Us"
                buttonLink="/contact"
            />
        </div>
    );
};

export default HomePage;
