import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/images/pump.jpg';
import img2 from '../assets/images/contracting.jpg';

const services = [
    {
        title: 'Trading Services',
        description: 'Providing high-quality products and reliable logistics across the globe.',
        image: img1,
        link: '/trading',
    },
    {
        title: 'Contracting Services',
        description: 'From planning to execution, we handle projects of any scale.',
        image: img2,
        link: '/contracting',
    },

];

const Services = () => {
    return (
        <section className="py-16 text-center bg-gray-100">
            <h2 className="text-5xl font-bold text-blue-900 mb-12">Our Services</h2>
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
                {services.map((service, index) => (
                    <div
                        key={index}
                        className="relative rounded-lg overflow-hidden shadow-lg"
                        style={{ height: '450px' }}
                    >
                        {/* Background Image */}
                        <div
                            className="absolute inset-0 bg-cover bg-center opacity-90"
                            style={{ backgroundImage: `url(${service.image})` }}
                        ></div>

                        {/* Overlay */}
                        <div className="absolute inset-0 bg-gradient-to-t from-white via-gray-200 to-transparent opacity-85"></div>

                        {/* Content */}
                        <div className="relative z-10 flex flex-col justify-center h-full p-6">
                            <h3 className="text-4xl font-bold text-center text-black mb-6">{service.title}</h3>
                            <div className="flex justify-center">
                                <Link to={service.link}>
                                    <button className="mt-6 px-10 py-3 bg-blue-900 text-white font-semibold text-lg rounded-lg hover:bg-blue-700 transition duration-300">
                                        Learn More
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>



    );
};

export default Services;
