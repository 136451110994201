import React from 'react';
import { NavLink } from 'react-router-dom';
import ISO_CERTIFICATE from '../assets/documents/AOTC_ISO_9001_USA.pdf';
import AOTCReg from '../assets/documents/AOTCReg.pdf';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white py-4">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                {/* Navigation Links */}
                <nav className="flex flex-wrap justify-center md:justify-start space-x-4 text-sm">
                    <NavLink
                        to="/"
                        className="text-gray-300 hover:text-white transition-colors duration-200 font-semibold no-underline"
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to="/trading"
                        className="text-gray-300 hover:text-white transition-colors duration-200 font-semibold no-underline"
                        activeClassName="font-bold"
                    >
                        Trading Services
                    </NavLink>
                    <NavLink
                        to="/contracting"
                        className="text-gray-300 hover:text-white transition-colors duration-200 font-semibold no-underline"
                        activeClassName="font-bold"
                    >
                        Contracting Services
                    </NavLink>
                    <div className="dropdown">
                        <NavLink
                            className="text-gray-300 hover:text-white transition-colors duration-200 font-semibold no-underline"
                            id="dropdownMenuButton"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Certifications
                        </NavLink>
                        <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{ bottom: '100%', left: 0 }}
                        >
                            <li>
                                <a
                                    className="dropdown-item"
                                    href={AOTCReg}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Wyoming Company Registration
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item"
                                    href={ISO_CERTIFICATE}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    ISO 9001:2015 IAF
                                </a>
                            </li>
                        </ul>
                    </div>
                    <NavLink
                        to="/careers"
                        className="text-gray-300 hover:text-white transition-colors duration-200 font-semibold no-underline"
                        activeClassName="font-bold"
                    >
                        Careers
                    </NavLink>
                    <NavLink
                        to="/about"
                        className="text-gray-300 hover:text-white transition-colors duration-200 font-semibold no-underline"
                        activeClassName="font-bold"
                    >
                        About Us
                    </NavLink>
                    <NavLink
                        to="/contact"
                        className="text-gray-300 hover:text-white transition-colors duration-200 font-semibold no-underline"
                        activeClassName="font-bold"
                    >
                        Contact Us
                    </NavLink>
                </nav>

                {/* Contact Information */}
                <div className="flex items-center space-x-6 text-sm">
                    <div className="flex items-center space-x-2">
                        <i className="bi bi-telephone-fill"></i>
                        <span>+1 (240) 885-1367</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <i className="bi bi-telephone-fill"></i>
                        <span>(+966) 557 629 896</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <i className="bi bi-envelope-fill"></i>
                        <span>info@aotcllcus.com</span>
                    </div>
                    <div className="flex space-x-4 items-center">
                        <a
                            href="https://x.com/AOTCLLC"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-lg cursor-pointer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-twitter-x"
                                viewBox="0 0 16 16"
                            >
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                            </svg>
                        </a>
                        <a
                            href="https://www.linkedin.com/company/105356242/admin/dashboard/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-lg cursor-pointer"
                        >
                            <i className="bi bi-linkedin text-lg"></i>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
