import React from 'react';
import { Link } from 'react-router-dom';

const CTASection = ({ title, description, buttonText, buttonLink }) => {
    return (
        <section className="py-16 bg-gradient-to-r from-blue-900 to-red-600 text-white text-center">
            <h2 className="text-3xl font-bold mb-4">{title}</h2>
            <p className="max-w-xl mx-auto mb-6">{description}</p>
            <Link to={buttonLink} className="px-6 py-3 bg-white text-blue-900 font-semibold rounded-lg hover:bg-gray-200 no-underline">
                {buttonText}
            </Link>
        </section>
    );
};

export default CTASection;
