import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/images/AOTC.png'; // Path to logo
import ISO from '../assets/images/ISO.jpeg';
import ISO_CERTIFICATE from '../assets/documents/AOTC_ISO_9001_USA.pdf';
import AOTCReg from '../assets/documents/AOTCReg.pdf';

function AppNavbar() {
    return (
        <Navbar expand="lg" bg="white" className="shadow-md py-3">
            <Container>
                {/* Logo */}
                <Navbar.Brand href="/" className="d-flex align-items-center">
                    <img src={logo} alt="AOTCLLC Logo" className="me-3" style={{ maxHeight: '100px', width: 'auto', objectFit: 'contain' }} />
                </Navbar.Brand>

                {/* Hamburger Menu for Mobile */}
                <Navbar.Toggle aria-controls="navbar-nav" />

                {/* Navbar Links */}
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="ms-auto text-lg fw-bold d-flex align-items-center">
                        <Nav.Link href="/" className="text-gray-700 hover:text-red-600 px-3">
                            Home
                        </Nav.Link>
                        <Nav.Link href="/trading" className="text-gray-700 hover:text-red-600 px-3">
                            Trading Services
                        </Nav.Link>
                        <Nav.Link href="/contracting" className="text-gray-700 hover:text-red-600 px-3">
                            Contracting Services
                        </Nav.Link>
                        {/* Dropdown Menu */}
                        <div className="dropdown">
                            <Nav.Link
                                className="dropdown-toggle text-gray-700 hover:text-red-600 px-3"
                                id="dropdownMenuButton"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Certifications
                            </Nav.Link>
                            <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                                style={{ top: '100%', left: 0 }}
                            >
                                <li>
                                    <a
                                        className="dropdown-item"
                                        href={AOTCReg}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Wyoming Company Registration
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item"
                                        href={ISO_CERTIFICATE}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        ISO 9001:2015 IAF
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <Nav.Link href="/careers" className="text-gray-700 hover:text-red-600 px-3">
                            Careers
                        </Nav.Link>
                        <Nav.Link href="/about" className="text-gray-700 hover:text-red-600 px-3">
                            About Us
                        </Nav.Link>
                        {/* Contact Button */}
                        <Nav.Link
                            href="/contact"
                            className="text-white px-4 py-2 rounded-pill shadow-md me-3"
                            style={{
                                background: 'linear-gradient(to right, #1d4ed8, #dc2626)', // Blue to Red gradient
                                color: 'white',
                                transition: 'transform 0.3s ease, background-color 0.3s ease', // Animation for scaling and smooth color transition
                            }}
                            onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')} // Scale up on hover
                            onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')} // Reset scaling
                        >
                            Contact Us
                        </Nav.Link>


                        <Nav.Link href={ISO_CERTIFICATE}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-flex align-items-center justify-content-center">

                            <img
                                src={ISO}
                                alt="ISO Certification"
                                className="ms-3"
                                style={{
                                    maxHeight: '100px', // Adjust height as needed
                                    width: '100px', // Maintain aspect ratio
                                    objectFit: 'contain',
                                }}
                            />

                        </Nav.Link>


                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
}

export default AppNavbar;
