import React from 'react';
import ameron from '../assets/suhaib-clients/ameron.png';
import amiantit from '../assets/suhaib-clients/amiantit.png';
import aramco from '../assets/suhaib-clients/aramco.png';
import aribiandrilling from '../assets/suhaib-clients/aribiandrilling.png';
import halliburton from '../assets/suhaib-clients/halliburton.png';
import sabic from '../assets/suhaib-clients/sabic.png';

const clients = [
    { src: ameron, alt: 'Ameron' },
    { src: amiantit, alt: 'Amiantit' },
    { src: aramco, alt: 'Saudi Aramco' },
    { src: aribiandrilling, alt: 'Arabian Drilling' },
    { src: halliburton, alt: 'Halliburton' },
    { src: sabic, alt: 'SABIC' },
];

const OurClients = () => {
    return (
        <section className="py-16 bg-gray-100 text-center">
            <h2 className="text-3xl font-bold text-blue-900 mb-8">Our Clients</h2>
            <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 px-4">
                {clients.map((client, index) => (
                    <div
                        key={index}
                        className="flex justify-center items-center transition-transform duration-300 hover:scale-110 hover:-translate-x-1"
                    >
                        <img
                            src={client.src}
                            alt={client.alt}
                            className="w-36 h-auto object-contain transform hover:scale-110 transition-transform duration-300"
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default OurClients;
